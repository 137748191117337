import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/whitelabelnft/Banner"
import Whatis from "../components/whitelabelnft/Whatis"
import WhyShould from "../components/whitelabelnft/WhyShould"
import TopFeatures from "../components/whitelabelnft/TopFeatures"
import HowOur from "../components/whitelabelnft/HowOur"
import CoreFeatures from "../components/whitelabelnft/CoreFeatures"
import SecurityofOur from "../components/whitelabelnft/SecurityofOur"
import UsecaseOf from "../components/whitelabelnft/UsecaseOf"
import WhyChoose from "../components/whitelabelnft/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
// import Review from "../components/Landing/Review"
// import PortfolioSection from "../components/nftexchange/Portfolio"
import DevApproach from "../components/whitelabelnft/DevApproach"
import TechStack from "../components/whitelabelnft/TechStack"
import FaqSection from "../components/whitelabelnft/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import Invaluable from "../components/whitelabelnft/Invaluable"
import Diverce from "../components/whitelabelnft/diverse"

// import Header from "../components/header"




const whitelabelnft = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>Best White Label NFT Marketplace Software - Coinsclone</title>
        <meta name="description" content="Get White Label NFT Marketplace Software from Coinsclone and kick-start a future-proof, all-in-one NFT marketplace to trade N-number of collectibles worldwide." />
        <meta name="keywords" content="White Label NFT Marketplace Development Company, NFT Marketplace App, Best White Label NFT Marketplace Cost, NFT Marketplace Solution" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Best White Label NFT Marketplace Software - Coinsclone" />
        <meta property="og:description" content="Get White Label NFT Marketplace Software from Coinsclone and kick-start a future-proof, all-in-one NFT marketplace to trade N-number of collectibles worldwide." />
        <meta property="og:url" content="https://www.coinsclone.com/white-label-nft-marketplace/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/white-label-nft-marketplace.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Get White Label NFT Marketplace Software from Coinsclone and kick-start a future-proof, all-in-one NFT marketplace to trade N-number of collectibles worldwide." />
        <meta name="twitter:title" content="Best White Label NFT Marketplace Software - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/white-label-nft-marketplace.png" />
        <link rel="canonical" href="https://www.coinsclone.com/white-label-nft-marketplace/" />
      </Helmet>
      {/* <Header /> */}
      <BannerSection />
      <div className="breadcrumb mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Whitelabel NFT Marketplace</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <Diverce />
      <TopFeatures />
      <HowOur />
      <CoreFeatures />
      <SecurityofOur />
      <Invaluable />
      <UsecaseOf />
      <WhyChoose />
      <Testimonial />
      {/* <Review />
      <PortfolioSection /> */}
      <DevApproach />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default whitelabelnft
